<template>
  <div class="add-car">
    <van-popup v-model="control.carType" position="bottom">
      <van-picker show-toolbar value-key="label" :columns="carTypeList" @confirm="carTypeConfirm"
                  @cancel="control.carType = false" />
    </van-popup>

    <van-popup v-model="control.plateType" position="bottom">
      <van-picker show-toolbar value-key="label" :columns="plateTypeList" @confirm="plateTypeConfirm"
                  @cancel="control.plateType = false" />
    </van-popup>

    <van-popup v-model="control.plateColor" position="bottom">
      <van-picker show-toolbar value-key="label" :columns="plateColorList" @confirm="plateColorConfirm"
                  @cancel="control.plateColor = false" />
    </van-popup>

    <van-popup v-model="control.carColor" position="bottom">
      <van-picker show-toolbar value-key="label" :columns="carColorList" @confirm="carColorConfirm"
                  @cancel="control.carColor = false" />
    </van-popup>

    <div class="form">
      <div class="title">车辆信息</div>
      <van-form input-align="right">
        <van-field required v-if="!this.$subarea" name="所属小区" label="所属小区" v-model="show.subarea" placeholder="请选择小区" @click="control.subareaShow = true" is-link/>
        <van-popup v-model="control.subareaShow" position="bottom">
          <van-picker show-toolbar value-key="name" :columns="subareaList" @confirm="subareaConfirm"
                      @cancel="control.subareaShow = false" />
        </van-popup>
        <van-field required name="车辆类型" label="车辆类型" placeholder="请选择车辆类型" :clickable="!readonly" readonly v-model="show.carType" @click="readonly ? '' : control.carType = true" is-link></van-field>
        <van-field required name="车牌号码" label="车牌号码" placeholder="请输入车牌号码" :readonly="readonly" v-model="dataForm.number"></van-field>
        <van-field name="车辆品牌" label="车辆品牌" placeholder="请输入车辆品牌" :readonly="readonly" v-model="dataForm.brand"></van-field>
        <van-field name="车牌类型" label="车牌类型" placeholder="请选择车牌类型" :clickable="!readonly" readonly v-model="show.plateType" @click="readonly ? '' :control.plateType = true" is-link></van-field>
        <van-field name="车牌颜色" label="车牌颜色" placeholder="请选择车牌颜色" :clickable="!readonly" readonly v-model="show.plateColor" @click="readonly ? '' :control.plateColor = true" is-link></van-field>
        <van-field name="车身颜色" label="车身颜色" placeholder="请选择车身颜色" :clickable="!readonly" readonly v-model="show.carColor" @click="readonly ? '' :control.carColor = true" is-link></van-field>
      </van-form>
    </div>
    <div class="car-btns">
      <div class="btn" v-if="!readonly && !id">
        <van-button class="one-btn" @click="onSubmit">提交</van-button>
      </div>
      <div class="btn" v-if="!readonly && id">
        <van-button class="two-btn white" @click="onCancel">取消</van-button>
        <van-button class="two-btn" @click="onSubmit">完成</van-button>
      </div>
      <div class="btn" v-if="readonly">
        <van-button class="two-btn white" @click="onDelete">删除</van-button>
        <van-button class="two-btn" @click="readonly = false">编辑</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: '',
      scId: '',
      sid: '',
      subarea: '',
      type: '',
      readonly: false,
      dataForm: {
        subarea: '',
        carType: '',
        number: '',
        brand: '',
        plateType: '',
        plateColor: '',
        carColor: '',
      },
      show: {
        subarea: '',
        carType: '',
        plateType: '',
        plateColor: '',
        carColor: '',
      },
      control: {
        subareaShow: false,
        carType: false,
        plateType: false,
        plateColor: false,
        carColor: false,
      },
      carTypeList: [],
      plateTypeList: [],
      plateColorList: [],
      carColorList: [],
      subareaList: []
    }
  },
  created() {
    if(this.$route.query.id) {
      this.id = this.$route.query.id
      this.scId = this.$route.query.scId
      this.sid = this.$route.query.sid
      this.subarea = this.$route.query.subarea
      this.readonly = true
      this.getCarInfo()
    }
    if(!this.$subarea) {
      this.getSubareaList()
    }
    this.getCarTypeList()
    this.getPlateTypeList()
    this.getPlateColorList()
    this.getCarColorList()
  },
  methods: {
    getSubareaList() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/org/communityList`),
        method: 'get',
        params: this.$http.adornParams({
          street: this.$orgId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.subareaList = data.communityList
        }
      })
    },
    subareaConfirm (e) {
      this.dataForm.subarea = parseInt(e.id)
      this.show.subarea = e.name
      this.control.subareaShow = false
    },
    getCarInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/devicecar/info'),
        method: 'GET',
        params: this.$http.adornParams({
          id: this.id,
          subarea: this.subarea
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.dataForm.carType = data.deviceCar.type
          this.show.carType = data.deviceCar.typeStr
          this.dataForm.number = data.deviceCar.carNumber
          this.dataForm.brand = data.deviceCar.brand
          this.dataForm.plateType = data.deviceCar.licencePlateType
          this.show.plateType = data.deviceCar.licencePlateTypeStr
          this.dataForm.plateColor = data.deviceCar.licencePlateColor
          this.show.plateColor = data.deviceCar.licencePlateColorStr
          this.dataForm.carColor = data.deviceCar.color
          this.show.carColor = data.deviceCar.colorStr
        }
      })
    },
    getCarTypeList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual'),
        method: 'GET',
        params: this.$http.adornParams({
          code: 'carType'
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.carTypeList = data.virtualDicts
        }
      })
    },
    getPlateTypeList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual'),
        method: 'GET',
        params: this.$http.adornParams({
          code: 'licencePlateType'
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.plateTypeList = data.virtualDicts
        }
      })
    },
    getPlateColorList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual'),
        method: 'GET',
        params: this.$http.adornParams({
          code: 'licencePlateColor'
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.plateColorList = data.virtualDicts
        }
      })
    },
    getCarColorList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual'),
        method: 'GET',
        params: this.$http.adornParams({
          code: 'carColor'
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.carColorList = data.virtualDicts
        }
      })
    },
    carTypeConfirm(value) {
      this.dataForm.carType = value.value
      this.show.carType = value.label
      this.control.carType = false
    },
    plateTypeConfirm(value) {
      this.dataForm.plateType = value.value
      this.show.plateType = value.label
      this.control.plateType = false
    },
    plateColorConfirm(value) {
      this.dataForm.plateColor = value.value
      this.show.plateColor = value.label
      this.control.plateColor = false
    },
    carColorConfirm(value) {
      this.dataForm.carColor = value.value
      this.show.carColor = value.label
      this.control.carColor = false
    },
    onDelete() {
      this.$dialog({
        message: '确定删除该车辆吗？',
        confirmButtonText: '确定',
        showCancelButton: true,
        cancelButtonText: '取消'
      }).then(() => {
        this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true, });
        this.$http({
          url: this.$http.adornUrl('/wxapp/devicecar/delete'),
          method: 'POST',
          data: this.$http.adornData([this.sid],false)
        }).then(({data}) => {
          this.$toast.clear()
          if(data && data.code === 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 1500,
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 1500);
          }else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    onCancel() {
      this.readonly = true
      this.getCarInfo()
    },
    onSubmit() {
      if(!this.$subarea && !this.dataForm.subarea) return this.$toast.fail('所属小区不能为空')
      if(!this.dataForm.carType) return this.$toast.fail('车辆类型不能为空')
      if(!this.dataForm.number) return this.$toast.fail('车牌号码不能为空')
      this.$toast.loading({ duration: 0, message: '提交中...', forbidClick: true, });
      this.$http({
        url: this.$http.adornUrl('/wxapp/devicecar/save'),
        method: 'POST',
        data: this.$http.adornData({
          id: this.id || '',
          scId: this.scId || '',
          userId: this.$userId,
          type: this.dataForm.carType,
          carNumber: this.dataForm.number,
          licencePlateType: this.dataForm.plateType,
          licencePlateColor: this.dataForm.plateColor,
          color: this.dataForm.carColor,
          temporary: 0,
          subarea: this.$subarea || this.dataForm.subarea,
          buildingId: this.$buildingId,
          houseId: this.$globalData.userInfo.houseId,
          brand: this.dataForm.brand,
          createUserId: this.$userId
        })
      }).then(({data}) => {
        this.$toast.clear()
        if(data && data.code === 0) {
          this.$toast.success({
            message: '提交成功',
            duration: 1500,
          })
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500);
        }else {
          this.$toast.fail(data.msg)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.add-car {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding-bottom: 40px;
}
.form {
  .title {
    // width: 750px;
    height: 96px;
    background: #FFFFFF;
    font-size: 32px;
    font-family: 'PingFangSC-Semibold';
    font-weight: 600;
    color: #333333;
    line-height: 96px;
    padding-left: 30px;
  }
}
.car-btns {
  padding: 0 30px;
  .btn {
    font-size: 32px;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    line-height: 88px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    .one-btn {
      width: 100%;
      height: 88px;
      background: #4581F8;
      border-radius: 8px;
      color: #FFFFFF;
    }
    .two-btn {
      width: 325px;
      height: 88px;
      background: #4581F8;
      border-radius: 8px;
      color: #FFFFFF;
    }
    .white {
      background: rgba(69,129,248,0);
      color: #4581F8;
      border: 2px solid #4581F8;
    }
  }
}
</style>
